/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/MDropdownMenu.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MInlineCalendar.vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "m-dropdown-menu__caret"
};
var _hoisted_2 = {
  class: "m-dropdown-menu__content-wrap"
};
export default {
  __name: 'DropdownCalendar',
  props: {
    modelValue: [String, Object],
    // 樣式
    caret: {
      type: Boolean,
      default: true
    },
    flatPickrConfig: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props) {
    var cData = reactive({
      allowRender: false
    });
    onMounted(function () {
      setTimeout(function () {
        cData.allowRender = true;
      }, 1000);
    });
    return function (_ctx, _cache) {
      var _component_MInlineCalendar = __unplugin_components_0;

      var _component_MDropdownMenu = __unplugin_components_1;

      return _openBlock(), _createBlock(_component_MDropdownMenu, {
        classes: "dropdown-calendar"
      }, {
        menu: _withCtx(function (slotProp) {
          return [__props.caret ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_unref(cData).allowRender ? (_openBlock(), _createBlock(_component_MInlineCalendar, {
            key: 0,
            "model-value": __props.modelValue,
            "flat-pickr-config": __props.flatPickrConfig,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('update:modelValue', $event);
            })
          }, null, 8, ["model-value", "flat-pickr-config"])) : _createCommentVNode("", true)])];
        }),
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      });
    };
  }
};